/**
 * Do not edit directly
 * Generated on Tue, 20 Aug 2024 04:38:21 GMT
 */

:root {
  --color-base-primary-light: #ffffff;
  --color-base-primary-medium: #363fbc;
  --color-base-primary-dark: #00013a;
  --color-base-text: #656487;
  --color-base-sport: #00b38d;
  --color-base-success: #8dc63f;
  --color-base-info: #00b1eb;
  --color-base-error: #f58b73;
  --color-base-link: #00aa25;
  --color-base-action: #00aa25;
  --color-font-base: #ffffff;
  --color-font-secondary: #00013a;
  --color-font-tertiary: #656487;
  --color-palette-white: #ffffff;
  --color-palette-black: #000000;
  --color-palette-bright: #363fbc;
  --color-palette-midnight: #00013a;
  --color-palette-teal: #00b38d;
  --color-palette-daylight: #00b1eb;
  --color-palette-mint: #74ed95;
  --color-palette-lime: #8dc63f;
  --color-palette-peach: #f58b73;
  --color-palette-red: #dd423c;
  --color-palette-violet: #9268ac;
  --color-palette-pinky: #f96db1;
  --color-palette-orange: #f26522;
  --color-palette-sunny: #fdb71a;
  --color-palette-twilight: #53508e;
  --color-palette-twilight-dark: #656487;
  --color-palette-twilight-light: #b2b2d6;
  --color-palette-purple: #414b75;
  --color-palette-purple-dark: #222250;
  --color-palette-storm: #070627;
  --color-palette-storm90: rgba(7, 6, 39, 0.9);
  --color-palette-storm75: rgba(7, 6, 39, 0.75);
  --color-palette-white60: rgba(255, 255, 255, 0.6);
  --color-palette-white20: rgba(255, 255, 255, 0.2);
  --color-palette-white10: rgba(255, 255, 255, 0.1);
  --color-palette-grey: #464646;
  --color-palette-grey-dark: #191919;
  --color-palette-grey-light: #d8d8d8;
  --color-palette-grey80: #464646;
  --color-palette-action: #00aa25;
  --color-palette-action-secondary: #078421;
  --color-palette-app-grey: #252525;
  --color-palette-background: #03003d;
  --color-palette-grey-medium: #969696;
  --font-base-heading-1-font-weight-xs: 900;
  --font-base-heading-1-font-weight-m: 900;
  --font-base-heading-1-font-weight-l: 900;
  --font-base-heading-1-font-weight-xl: 900;
  --font-base-heading-1-font-size-xs: 24px;
  --font-base-heading-1-font-size-s: 32px;
  --font-base-heading-1-font-size-m: 38px;
  --font-base-heading-1-font-size-l: 48px;
  --font-base-heading-1-font-size-xl: 60px;
  --font-base-heading-1-line-height-xs: 28px;
  --font-base-heading-1-line-height-s: 36px;
  --font-base-heading-1-line-height-m: 42px;
  --font-base-heading-1-line-height-l: 52px;
  --font-base-heading-1-line-height-xl: 64px;
  --font-base-heading-2-font-weight-xs: 700;
  --font-base-heading-2-font-weight-m: 700;
  --font-base-heading-2-font-size-xs: 20px;
  --font-base-heading-2-font-size-m: 24px;
  --font-base-heading-2-font-size-l: 24px;
  --font-base-heading-2-line-height-xs: 24px;
  --font-base-heading-2-line-height-l: 28px;
  --font-base-heading-3-font-weight-xs: 700;
  --font-base-heading-3-font-weight-m: 700;
  --font-base-heading-3-font-size-xs: 18px;
  --font-base-heading-3-font-size-s: 24px;
  --font-base-heading-3-font-size-xl: 30px;
  --font-base-heading-3-line-height-xs: 20px;
  --font-base-heading-3-line-height-s: 28px;
  --font-base-heading-3-line-height-xl: 34px;
  --font-base-heading-4-font-weight-xs: 700;
  --font-base-heading-4-font-weight-m: 700;
  --font-base-heading-4-font-weight-xl: 700;
  --font-base-heading-4-font-size-xs: 12px;
  --font-base-heading-4-font-size-m: 14px;
  --font-base-heading-4-font-size-xl: 16px;
  --font-base-heading-4-line-height-xs: 14px;
  --font-base-heading-4-line-height-m: 18px;
  --font-base-heading-4-line-height-xl: 20px;
  --font-base-heading-4-text-transform-xs: uppercase;
  --font-base-heading-4-text-transform-m: uppercase;
  --font-base-heading-4-text-transform-xl: uppercase;
  --font-base-heading-4-letter-spacing-xs: 0.75px;
  --font-base-heading-4-letter-spacing-m: 1px;
  --font-base-heading-4-letter-spacing-xl: 1px;
  --font-base-heading-5-font-weight-xs: 900;
  --font-base-heading-5-font-weight-s: 900;
  --font-base-heading-5-font-weight-m: 900;
  --font-base-heading-5-font-weight-l: 900;
  --font-base-heading-5-font-weight-xl: 900;
  --font-base-heading-5-font-size-xs: 36px;
  --font-base-heading-5-font-size-s: 40px;
  --font-base-heading-5-font-size-m: 60px;
  --font-base-heading-5-font-size-l: 82px;
  --font-base-heading-5-font-size-xl: 82px;
  --font-base-heading-5-line-height-xs: 42px;
  --font-base-heading-5-line-height-s: 44px;
  --font-base-heading-5-line-height-m: 64px;
  --font-base-heading-5-line-height-l: 86px;
  --font-base-heading-5-line-height-xl: 86px;
  --font-base-heading-6-font-weight-xs: 300;
  --font-base-heading-6-font-weight-m: 300;
  --font-base-heading-6-font-size-xs: 12px;
  --font-base-heading-6-font-size-s: 14px;
  --font-base-heading-6-font-size-xl: 26px;
  --font-base-heading-6-line-height-xs: 14px;
  --font-base-heading-6-line-height-s: 18px;
  --font-base-heading-6-line-height-xl: 28px;
  --font-base-body-1-font-weight-xs: 400;
  --font-base-body-1-font-weight-s: 400;
  --font-base-body-1-font-weight-m: 400;
  --font-base-body-1-font-weight-l: 400;
  --font-base-body-1-font-weight-xl: 400;
  --font-base-body-1-font-size-xs: 16px;
  --font-base-body-1-font-size-s: 16px;
  --font-base-body-1-font-size-m: 16px;
  --font-base-body-1-font-size-l: 18px;
  --font-base-body-1-font-size-xl: 24px;
  --font-base-body-1-line-height-xs: 20px;
  --font-base-body-1-line-height-s: 20px;
  --font-base-body-1-line-height-m: 20px;
  --font-base-body-1-line-height-l: 22px;
  --font-base-body-1-line-height-xl: 30px;
  --font-base-body-2-font-weight-xs: 400;
  --font-base-body-2-font-weight-s: 400;
  --font-base-body-2-font-weight-m: 400;
  --font-base-body-2-font-weight-l: 400;
  --font-base-body-2-font-weight-xl: 400;
  --font-base-body-2-font-size-xs: 14px;
  --font-base-body-2-font-size-s: 14px;
  --font-base-body-2-font-size-m: 14px;
  --font-base-body-2-font-size-l: 16px;
  --font-base-body-2-font-size-xl: 18px;
  --font-base-body-2-line-height-xs: 18px;
  --font-base-body-2-line-height-s: 18px;
  --font-base-body-2-line-height-m: 18px;
  --font-base-body-2-line-height-l: 20px;
  --font-base-body-2-line-height-xl: 22px;
  --font-base-body-3-font-weight-xs: 400;
  --font-base-body-3-font-weight-s: 400;
  --font-base-body-3-font-weight-m: 400;
  --font-base-body-3-font-weight-l: 400;
  --font-base-body-3-font-weight-xl: 400;
  --font-base-body-3-font-size-xs: 12px;
  --font-base-body-3-font-size-s: 12px;
  --font-base-body-3-font-size-m: 12px;
  --font-base-body-3-font-size-l: 14px;
  --font-base-body-3-font-size-xl: 14px;
  --font-base-body-3-line-height-xs: 14px;
  --font-base-body-3-line-height-s: 16px;
  --font-base-body-3-line-height-m: 16px;
  --font-base-body-3-line-height-l: 18px;
  --font-base-body-3-line-height-xl: 18px;
  --size-layout-responsive-root-font-size: 100px;
  --size-layout-responsive-design-ratio-xs: 1;
  --size-layout-responsive-design-ratio-m: 1;
  --size-layout-responsive-design-width-xs: 375px;
  --size-layout-responsive-design-width-m: 1920px;
  --size-layout-breakpoint-xs: 320px; /* XS, Mobile */
  --size-layout-breakpoint-s: 600px; /* S Mobile, Tablet */
  --size-layout-breakpoint-m: 1024px; /* M,  Tablet Landscape + Small Desktop */
  --size-layout-breakpoint-l: 1366px; /* L,  Desktop */
  --size-layout-breakpoint-xl: 1920px; /* XL,  Desktop */
  --size-layout-spacing-xxs: 4px;
  --size-layout-spacing-xs: 8px;
  --size-layout-spacing-s: 12px;
  --size-layout-spacing-m: 16px;
  --size-layout-spacing-l: 24px;
  --size-layout-spacing-xl: 32px;
  --size-layout-spacing-xxl: 52px;
  --size-layout-spacing-xxxl: 80px;
  --size-layout-border-radius-default: 2px;
  --size-layout-gutter-xs: 4px;
  --size-layout-gutter-s: 8px;
  --size-layout-gutter-m: 12px;
  --size-layout-gutter-l: 16px;
  --size-layout-gutter-xl: 16px;
  --size-layout-grid-col-number-xs: 4;
  --size-layout-grid-col-number-s: 12;
  --size-layout-grid-col-width-xs: 21px;
  --size-layout-grid-col-width-m: 129px;
  --size-layout-grid-edge-width-xs: 16px;
  --size-layout-grid-edge-width-s: 20px;
  --size-layout-grid-edge-width-m: 40px;
  --size-layout-grid-edge-width-l: 80px;
  --size-layout-grid-edge-width-xl-1680: 98px;
  --size-layout-grid-edge-width-xl-2560: 230px;
  --size-layout-section-xs: 40px;
  --size-layout-section-m: 60px;
  --size-layout-section-xl: 80px;
}
